// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dang-ky-js": () => import("./../../../src/pages/dang-ky.js" /* webpackChunkName: "component---src-pages-dang-ky-js" */),
  "component---src-pages-dang-nhap-js": () => import("./../../../src/pages/dang-nhap.js" /* webpackChunkName: "component---src-pages-dang-nhap-js" */),
  "component---src-pages-dieu-khoan-js": () => import("./../../../src/pages/dieu-khoan.js" /* webpackChunkName: "component---src-pages-dieu-khoan-js" */),
  "component---src-pages-gioi-thieu-js": () => import("./../../../src/pages/gioi-thieu.js" /* webpackChunkName: "component---src-pages-gioi-thieu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lien-he-js": () => import("./../../../src/pages/lien-he.js" /* webpackChunkName: "component---src-pages-lien-he-js" */),
  "component---src-pages-quen-mat-khau-js": () => import("./../../../src/pages/quen-mat-khau.js" /* webpackChunkName: "component---src-pages-quen-mat-khau-js" */),
  "component---src-templates-app-embedded-js": () => import("./../../../src/templates/AppEmbedded.js" /* webpackChunkName: "component---src-templates-app-embedded-js" */),
  "component---src-templates-app-mount-js": () => import("./../../../src/templates/AppMount.js" /* webpackChunkName: "component---src-templates-app-mount-js" */)
}

