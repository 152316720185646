import NProgress from 'nprogress';

export const onClientEntry = () => {};

let isFinished = true;
const DELAY_TIME = 250;

export const onRouteUpdate = () => {
  isFinished = true;
  setTimeout(NProgress.done, DELAY_TIME);
};

export const onPreRouteUpdate = () => {
  isFinished = false;
  setTimeout(() => {
    if (!isFinished) {
      NProgress.start();
    }
  }, DELAY_TIME);
};
